<template>
    <div class="container">
        <div class="bg-white p-2">

            <div class="my-1">
                <h2>Aliff Courses</h2>
            </div>
            <div class="mt-1">
                <button class="btn p-2 w-100 bg-primary text-white " @click="toggleCollapse" type="button"
                    :aria-expanded="isCollapsed" aria-controls="collapse1">
                    Courses {{ isCollapsed ? '↓' : '↑' }}
                </button>
            </div>
            <div :class="['collapse', { show: !isCollapsed }]" id="collapse1">
                <div class="row justify-content-center my-5 gap-4">
                    
                    <div v-for="course in courses" :key="course.id" class="col-12 col-sm-4">
                        <div class="embed-responsive embed-responsive-16by9 p-3">
                            <iframe style="display: block; border-radius: 10px;" :src="course.videoUrl" title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                        </div>
                        <div class="p-2">
                            <h3>{{ course.title }}</h3>
                        </div>
                    </div>
                </div>
              <!-- modal -->
              <div>
  <b-button class="btn p-2  bg-primary text-white" v-b-modal.modal-1>Take the quiz</b-button>

  <b-modal :no-close-on-backdrop="true" id="modal-1" title="Aliff Quiz">
    <!-- <p class="my-4">Hello from modal!</p> -->
    <form>
  <fieldset class="mb-2">
    <legend>1. Lorem ipsum dolor sit.?</legend>
    <label>
      <input type="radio" name="q1" value="A"> Lorem ipsum dolor sit amet.
    </label><br>
    <label>
      <input type="radio" name="q1" value="B"> Lorem ipsum dolor sit amet consectetur.
    </label><br>
    <label>
      <input type="radio" name="q1" value="C"> Lorem ipsum dolor sit amet.
    </label><br>
    <label>
      <input type="radio" name="q1" value="D"> Lorem ipsum dolor sit amet consectetur adipisicing elit.
    </label><br>
  </fieldset>

  <fieldset class="mb-2">
    <legend>2. Lorem ipsum dolor sit amet consectetur.?</legend>
    <label>
      <input type="radio" name="q1" value="A"> Lorem ipsum dolor sit amet.
    </label><br>
    <label>
      <input type="radio" name="q1" value="B"> Lorem ipsum dolor sit amet consectetur.
    </label><br>
    <label>
      <input type="radio" name="q1" value="C"> Lorem ipsum dolor sit amet.
    </label><br>
    <label>
      <input type="radio" name="q1" value="D"> Lorem ipsum dolor sit amet consectetur adipisicing elit.
    </label><br>
  </fieldset>

  <!-- <input type="submit" value="Submit"> -->
</form>

  </b-modal>
</div>
            </div>




        </div>

    </div>
</template>
  
<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BAvatar,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { FILESURL } from "@/config";
import AgentServices from "@/apiServices/AgentServices";
import { data } from "vue-echarts";
export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
    data() {
        return {
          trainings: [],
      FILESURL,
            isCollapsed: true,
            courses: [
                {
                    id: 1,
                    title: 'Course 1',
                    videoUrl: 'https://www.youtube.com/watch?v=2Izuq4Txr-w'
                },
                {
                    id: 2,
                    title: 'Course 2',
                    videoUrl: 'https://www.youtube.com/watch?v=2Izuq4Txr-w'
                },
                {
                    id: 3,
                    title: 'Course 3',
                    videoUrl: 'https://www.youtube.com/watch?v=2Izuq4Txr-w'
                },
                {
                    id: 4,
                    title: 'Course 4',
                    videoUrl: 'https://www.youtube.com/watch?v=2Izuq4Txr-w'
                },
                {
                    id: 5,
                    title: 'Course 5',
                    videoUrl: 'https://www.youtube.com/watch?v=2Izuq4Txr-w'
                },
                {
                    id: 6,
                    title: 'Course 6',
                    videoUrl: 'https://www.youtube.com/watch?v=2Izuq4Txr-w'
                },
                
            ]
        };
    },
    methods: {
        toggleCollapse() {
            this.isCollapsed = !this.isCollapsed;
        },
        async getTrainings() {
      try {
        const res = await AgentServices.getFranchiseTrainings();
        if (res.data.status) {
          this.trainings = res.data.data;
        }
      } catch (error) {
        console.error("Error in getTrainings ", error);
      }
    },
    beforeMount() {
    this.getTrainings();
  },
        
    }
};
</script>
  
<style scoped>

</style>
  